Vue.component('categorySectionComponent', {
    props: ['selectCategory',"displayCategory"],
    data: function () {
        return {
            display: {
                categories: true,
                prices: true,
                filterHistory:true,
            }
        }
    },
    template: `<section class="filters-section">
                  <div class="title-container">
                       <h5 class="white-text">FILTROS</h5>
                  </div>
                  <div class="filters-container">
                      <template v-for="filter of $store.getters.getItemFilters.filter((x)=>{return x.fields.FilterActived})">
                          <filterProxyComponent :filterField="filter.fields"></filterProxyComponent>
                      </template>
                  </div>
              </section>`,
});
